import axios, { AxiosResponse } from 'axios'
import { IReqListType } from '../types/reqList'

async function useShareLink(shareArr: IReqListType[]): Promise<{ code: string; message: string }> {
  const { data }: AxiosResponse = await axios.post(`https://d-drive.api.innopam.kr/api/v1/link`, {
    shareMedia: shareArr,
  })

  return data
}

export default useShareLink
