import React from 'react'
import { Box, CircularProgress, styled } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import ImageCaptioningViewAllContainer from './ImageCaptioningViewAllContainer'
import useTags, { TTags } from '../hooks/useTags'
import CaptioningList from '../components/CaptioningList'

const Wrapper = styled(Box)`
  width: 100%;
  height: 90vh;

  padding: 0 40px;
  overflow-y: scroll;
  overflow-x: hidden;
`

interface IImageCaptioningListContainer {
  drawerWidth: number
}

function ImageCaptioningListContainer({ drawerWidth }: IImageCaptioningListContainer) {
  const [queryParameters] = useSearchParams()
  const {
    data: captioningData,
    isLoading,
    isError,
  } = useTags(
    '',
    queryParameters.get('view-all') === 'true'
      ? { tagType: (queryParameters.get('type') ?? '').toLocaleLowerCase() }
      : { limit: 5 },
  )

  if (isError && captioningData === undefined) {
    return <Wrapper>There is some error</Wrapper>
  }

  if (isLoading && captioningData === undefined) {
    return (
      <Wrapper>
        <CircularProgress />
      </Wrapper>
    )
  }

  if (queryParameters.get('view-all') === 'true') {
    let key: TTags = 'timeTagData'
    if (queryParameters.get('type') === 'Place') {
      key = 'placeTagData'
    } else if (queryParameters.get('type') === 'Object') {
      key = 'objectTagData'
    }
    return (
      <Wrapper>
        <ImageCaptioningViewAllContainer
          title={queryParameters.get('type') ?? ''}
          list={captioningData !== undefined ? captioningData[key] : []}
        />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <CaptioningList
        drawerWidth={drawerWidth}
        title='Place'
        list={captioningData?.placeTagData.slice(0, 5)}
      />
      <CaptioningList
        drawerWidth={drawerWidth}
        title='Object'
        list={captioningData?.objectTagData.slice(0, 5)}
      />
      <CaptioningList
        drawerWidth={drawerWidth}
        title='Time'
        list={captioningData?.timeTagData.slice(0, 5)}
      />
    </Wrapper>
  )
}

export default ImageCaptioningListContainer
