import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import apis from '../service/apis'
import { IDto } from '../types/Dto'
import { IMedia } from '../types/medias'

interface IQuery {
  [key: string]: string | number | undefined

  minX?: number
  minY?: number
  maxX?: number
  maxY?: number
}

const useImageData = (textValue: string, query: IQuery | undefined, path: string) =>
  useQuery<IDto<IMedia[]>, AxiosError, IMedia[]>(
    ['getImageData', textValue, query],
    async () => apis.get<IQuery, IDto<IMedia[]>>(path, query),
    {
      select: (data) => data.data,
    },
  )

export default useImageData
