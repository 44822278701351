import { LinearProgress, linearProgressClasses, styled } from '@mui/material'
import React from 'react'

const Wrapper = styled('div')`
  background: rgba(241, 241, 241, 0.8);
  border-radius: 4px;
  padding: 10px 6px;

  font-family: 'Noto Sans NKo', sans-serif;
  font-style: normal;
  line-height: 100%;

  .gray__text {
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.09em;
    color: #a9a9a9;
  }

  .decription__wrapper {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 12px;
    color: #111111;
  }
`

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: '16px',
  marginTop: '4px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#fff',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '16px',
    backgroundColor: '#256197',
  },
}))

export default function LoadingBar({ time, index }: { index: number; time: number }) {
  return (
    <Wrapper>
      <span className='gray__text'> {index < 10 ? `0${index}` : index}.</span>
      <div className='decription__wrapper'>
        <span className='left'>{time !== 100 ? 'Uploading...' : 'Completed'}</span>
        <span className='right'>{time} / 100</span>
      </div>
      <BorderLinearProgress variant='determinate' value={time} />
    </Wrapper>
  )
}
