import React, { useEffect, useRef, useState } from 'react'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import PauseIcon from '@mui/icons-material/Pause'
import { Button, styled } from '@mui/material'
import VideoProgressBar from './VideoProgressBar'
import useVideoTime from '../states/useVideoTime'
import useBoolean from '../hooks/useBoolean'
import useSelectedImage from '../states/useSelectedImage'

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  padding: 0 20px;
  color: #fff;

  .control-wrapper {
    padding: 0 20px;
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);
  }

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & button {
    width: fit-content;
    height: fit-content;
    background: transparent;
    border-width: 0;
    color: #fff;
  }

  & .isWaiting {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  & .circle {
    color: white;
  }
`

interface IVideo {
  setFalse: () => void
  isLoading: boolean
}

function Video({ setFalse, isLoading }: IVideo) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoSrc = useSelectedImage((state) => state.selectedSrc)
  const { value: isCheckStart, toggle } = useBoolean(false)
  const {
    currentTime,
    setCurrentTime,
    videoTime,
    setVideoTime,
    isStarting,
    setVideoStarting,
    setVideoStop,
    setVideoRef,
  } = useVideoTime()
  const [progress, setProgress] = useState(0)

  const handlerMoveTimeStamp = (time: number) => async () => {
    if (videoRef.current === null) return
    // eslint-disable-next-line no-param-reassign
    // videoRef.current.currentTime = time
    videoRef.current.currentTime = time
    setCurrentTime(time)
  }

  useEffect(() => {
    setProgress((currentTime / videoTime) * 100)
  }, [currentTime, videoTime])

  const handlerToggleFullScreen = async () => {
    if (videoRef.current === null) return
    await videoRef.current.requestFullscreen()
  }

  const handlerTogglePlay = async () => {
    if (videoRef.current === null) return
    if (videoRef.current.paused) {
      await videoRef.current.play()
    } else {
      videoRef.current.pause()
    }

    toggle()
  }

  useEffect(() => {
    if (videoRef.current === null) return
    if (videoSrc === undefined) return

    videoRef.current.src = videoSrc
  }, [videoSrc])

  return (
    <Wrapper style={{ display: !isLoading ? 'block' : 'none' }}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        controls={false}
        ref={videoRef}
        className='video'
        style={{ display: !isLoading ? 'block' : 'none' }}
        width='100%'
        height='100%'
        onLoadedData={() => {
          setFalse()
          if (videoRef.current === null) return

          setVideoRef(videoRef)
        }}
        onLoadedMetadata={() => {
          if (videoRef.current !== null) {
            setVideoTime(videoRef.current.duration)
            setCurrentTime(0)
          }
        }}
        onTimeUpdate={() => {
          if (videoRef.current === null) return
          if (!isCheckStart) return
          if (videoTime > 0) {
            setCurrentTime(videoRef.current.currentTime)
            const newProgress = (videoRef.current.currentTime / videoTime) * 100
            if (!Number.isNaN(newProgress)) {
              setProgress(newProgress)
            }
          }
        }}
        onPause={setVideoStop}
        onPlay={setVideoStarting}
      />
      <div className='control-wrapper'>
        <div className='controls'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Button onClick={handlerTogglePlay}>
              {isStarting ? <PauseIcon /> : <PlayArrowIcon />}
            </Button>
            {videoRef.current !== null && (
              <span className='controlsTime'>
                {`${Math.floor(currentTime / 60)}:${`0${Math.floor(currentTime % 60)}`.slice(-2)}`}{' '}
                /{`${Math.floor(videoTime / 60)}:${`0${Math.floor(videoTime % 60)}`.slice(-2)}`}
              </span>
            )}
          </div>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Button onClick={handlerToggleFullScreen}>
            <FullscreenIcon />
          </Button>
        </div>
        <VideoProgressBar
          handlerMoveTimeStamp={handlerMoveTimeStamp}
          percentage={progress}
          videoTime={videoTime}
        />
      </div>
    </Wrapper>
  )
}

export default Video
