import React from 'react'
import { Skeleton, styled } from '@mui/material'
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded'
import { LatLngExpression } from 'leaflet'
import useSelectedImageList from '../states/useSelectedImageList'
import useSelectedImage from '../states/useSelectedImage'
import useBoolean from '../hooks/useBoolean'
import { TCoordinates, TMediaType } from '../types/medias'
import useExternalMap from '../states/useExternalMap'
import useVideoTime from '../states/useVideoTime'

interface IImageCard {
  src: string
  thumb: string
  isSelected: boolean
  title: string
  type: TMediaType
  handlerSetLoading: () => void
  mediaId: string
  coordinates: TCoordinates
  geom: { type: string; coordinates: TCoordinates }
  handlerMoveTop: () => void
}

const Wrapper = styled('div')`
  position: relative;
  width: 150px;
  height: fit-content;
  cursor: pointer;

  img {
    width: calc(150px - 1rem);
    height: calc(150px - 1.3rem);
    cursor: pointer;
    border-radius: 2px;
  }

  svg {
    fill: white;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const ImageButton = styled('button')`
  background-color: transparent;
  border: 0;
`

const ImageCardTitle = styled('div')`
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Circle = styled('div')<{ isSelected: boolean }>`
  width: 16px;
  height: 16px;

  border-radius: 50%;
  border: 2px solid ${({ isSelected }) => (isSelected ? 'green' : '#EAEAEA')};

  background: ${({ isSelected }) => (isSelected ? 'green' : 'rgba(255, 255, 255, 0.5)')};

  position: absolute;
  top: 16px;
  right: 16px;

  cursor: pointer;
`

const ImageCard = React.memo(
  ({
    src,
    thumb,
    isSelected,
    title,
    type,
    handlerSetLoading,
    mediaId,
    coordinates,
    handlerMoveTop,
    geom,
  }: IImageCard) => {
    const { value: isLoading, setFalse } = useBoolean(true)

    const toggleList = useSelectedImageList((state) => state.toggleList)
    const { toggleSelectedSrc } = useSelectedImage((state) => state)
    const setVideoStop = useVideoTime((state) => state.setVideoStop)
    const { map } = useExternalMap()

    const handlerClickCard = (e: React.MouseEvent<HTMLElement>) => {
      if (isLoading) return
      e.stopPropagation()
      handlerSetLoading()
      toggleSelectedSrc(src, thumb, type, geom, mediaId)

      handlerMoveTop()
      if (map !== null) {
        const temp = [...coordinates]
        map.flyTo(temp.reverse() as LatLngExpression, 19)
      }
      setVideoStop()
    }

    return (
      <Wrapper data-testid='image-in-ImageList' onClick={handlerClickCard}>
        <ImageButton type='button' data-testid='button-in-ImageList'>
          <img
            style={{ display: !isLoading ? 'block' : 'none' }}
            src={thumb}
            onLoad={() => {
              setFalse()
            }}
            alt='drone data'
            data-testid={src}
          />
          <Skeleton
            sx={{ display: isLoading ? 'block' : 'none' }}
            variant='rectangular'
            width='calc(150px - 1rem)'
            height='calc(150px - 1.3rem)'
          />
        </ImageButton>
        <ImageCardTitle>{title}</ImageCardTitle>
        <Circle
          data-testid='toggleButton-in-ImageCard'
          isSelected={isSelected}
          onClick={(e) => {
            e.stopPropagation()
            toggleList(mediaId, type)
          }}
        />

        {type === 'video' && <PlayCircleOutlineRoundedIcon />}
      </Wrapper>
    )
  },
)

export default ImageCard
