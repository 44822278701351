import React, { useRef } from 'react'
import { CircularProgress, styled } from '@mui/material'
import MetaDataButton from './MetaDataButton'
import useBoolean from '../hooks/useBoolean'
import MetaDataPopover from './MetaDataPopover'

const Wrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  z-index: 990;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  & .background-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

  & .full-image-container {
    position: relative;
    display: flex;
    justify-content: center;
  }

  & .meta-button-container {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .loading {
    display: none;
  }
`

interface IFullScreen {
  src: string
  handlerClickFullScreen: (e: React.MouseEvent<HTMLElement>) => void
}

function FullScreen({ src, handlerClickFullScreen }: IFullScreen) {
  const { value: isOpen, toggle: handlerOpenCloseMeta, setFalse } = useBoolean(false)
  const imgRef = useRef<HTMLImageElement>(null)
  const { value: isLoading, setFalse: setDone } = useBoolean(true)

  return (
    <Wrapper data-testid='full-screen-image'>
      <div className='full-image-container'>
        <img
          ref={imgRef}
          src={src}
          alt='middle size'
          className={isLoading ? 'loading' : ''}
          onLoad={(event) => {
            const image = event.target as HTMLImageElement
            const { width, height } = image
            if (width >= height) {
              image.style.maxHeight = '80vh'
              image.style.width = 'auto'
            } else {
              image.style.maxWidth = '80vw'
              image.style.height = 'auto'
            }
            setDone()
          }}
        />
        {!isLoading && (
          <div className='meta-button-container'>
            <MetaDataButton handleOpen={handlerOpenCloseMeta} />
            <MetaDataPopover isVisible={isOpen} handleClose={setFalse} />
          </div>
        )}
        {isLoading && <CircularProgress />}
      </div>
      <div role='presentation' className='background-container' onClick={handlerClickFullScreen} />
    </Wrapper>
  )
}

export default FullScreen
