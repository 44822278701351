import create from 'zustand'

interface IDialogState {
  isUploadOpen: boolean
  isLoadingOpen: boolean

  isProfileOpen: boolean
  isShareLinkOpen: boolean

  setDialogState: (select: {
    isUploadOpen: boolean
    isLoadingOpen: boolean
    isProfileOpen: boolean
    isShareLinkOpen: boolean
  }) => void
}

const useDialogState = create<IDialogState>()((set) => ({
  isUploadOpen: false,
  isLoadingOpen: false,
  isProfileOpen: false,
  isShareLinkOpen: false,
  setDialogState: (data) =>
    set({
      isUploadOpen: data.isUploadOpen,
      isLoadingOpen: data.isLoadingOpen,
      isProfileOpen: data.isProfileOpen,
      isShareLinkOpen: data.isShareLinkOpen,
    }),
}))

export default useDialogState
