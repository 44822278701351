import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { TMediaType } from '../types/medias'

export type ISelectedList = Map<string, TMediaType>
export type IToggleList = (id: string, type: TMediaType) => void

interface IUseSelectedImageList {
  list: ISelectedList
  toggleList: IToggleList
  clearList: () => void
}

const useSelectedImageList = create<IUseSelectedImageList>()(
  devtools((set) => ({
    list: new Map(),
    toggleList: (id: string, type: TMediaType) =>
      set((state) => {
        const newList = new Map(state.list)
        if (newList.has(id)) {
          newList.delete(id)
        } else {
          newList.set(id, type)
        }
        return { list: newList }
      }),
    clearList: () => {
      set(() => ({ list: new Map() }))
    },
  })),
)

export default useSelectedImageList
