import { CssBaseline, ThemeProvider } from '@mui/material'
import { CookiesProvider } from 'react-cookie'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import theme from './config/styles/theme'
import App from './pages/App'
import Callback from './pages/Callback'
import Share from './pages/Share'
import Login from './pages/Login'

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <React.StrictMode>
        <CookiesProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </QueryClientProvider>
        </CookiesProvider>
      </React.StrictMode>
    ),
  },
  {
    path: '/login',
    element: (
      <React.StrictMode>
        <CookiesProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Login />
            </ThemeProvider>
          </QueryClientProvider>
        </CookiesProvider>
      </React.StrictMode>
    ),
  },
  {
    path: '/:type&:filter&:view-all',
    element: (
      <React.StrictMode>
        <CookiesProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </QueryClientProvider>
        </CookiesProvider>
      </React.StrictMode>
    ),
  },
  {
    path: '/api/v1/oauth/callback',
    element: (
      <React.StrictMode>
        <CookiesProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Callback />
            </ThemeProvider>
          </QueryClientProvider>
        </CookiesProvider>
      </React.StrictMode>
    ),
  },
  {
    path: '/share/:id',
    element: (
      <React.StrictMode>
        <CookiesProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Share />
            </ThemeProvider>
          </QueryClientProvider>
        </CookiesProvider>
      </React.StrictMode>
    ),
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<RouterProvider router={router} />)
