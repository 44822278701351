import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import useGoogleLoginCallback from '../hooks/useGoogleLoginCallback'
import useUserData from '../states/useUserData'

function Callback() {
  const [cookies, setCookie] = useCookies<string>(['Authorization'])
  const navigate = useNavigate()
  const location = useLocation()
  const { data } = useGoogleLoginCallback(`${location.pathname}${location.search}`)

  React.useEffect(() => {
    if (data !== null && data !== undefined) {
      setCookie('Authorization', data.data.accessToken, { path: '/' })
      if (cookies.Authorization !== undefined) {
        useUserData.setState({ userData: data.data })

        navigate('/')
      }
    }
  }, [navigate, data, setCookie, cookies])

  return <>...</>
}

export default Callback
