import React from 'react'
import { useLocation } from 'react-router-dom'
import Search from './Search'
import ProfileButton from './ProfileButton'
import UploadButton from './UploadButton'

function Header() {
  const location = useLocation()
  const [search, setSearch] = React.useState('')

  return (
    <>
      <Search textValue={search} handlerChangeText={(e) => setSearch(e.target.value)} />
      {!location.pathname.includes('share') && (
        <>
          <UploadButton />
          <ProfileButton />
        </>
      )}
    </>
  )
}

export default Header
