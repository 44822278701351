import { styled } from '@mui/material'
import React from 'react'
import MacBookIcon from '../assets/image/macbook-icon.svg'

const Wrapper = styled('div')`
  font-family: 'Noto Sans NKo', sans-serif;
  font-style: normal;
  line-height: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #707070;

  .icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .upload__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #efefef;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 12px;
  }

  input[type='file'] {
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
`

function MyComputerUploadButton({ handleChange }: { handleChange: any }) {
  return (
    <Wrapper>
      <form data-testid='fileUpload'>
        <label htmlFor='file-upload' className='upload__btn'>
          <input
            id='file-upload'
            type='file'
            data-testid='computerFileUpload'
            onChange={handleChange}
            multiple
            accept='image/*'
          />
          <img src={MacBookIcon} alt='my computer' className='icon' />
        </label>
      </form>
      <span className='btn__title'>My Computer</span>
    </Wrapper>
  )
}

export default MyComputerUploadButton
