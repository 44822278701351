import React, { ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider, InputBase, Paper, styled } from '@mui/material'
import Logo from '../assets/image/Ddrive.svg'
import SearchIcon from '../assets/image/search.svg'
import { top } from '../config/styles/commonStyles'

const Wrapper = styled('div')`
  position: fixed;
  width: 500px;
  left: 20px;
  top: ${top};

  z-index: 500;
  .icon_btn {
    width: 36px;
    height: 36px;
    filter: drop-shadow(0px 0px 28px rgba(37, 96, 151, 0.25));
    background-color: #256197;
    border-radius: 4px;
    color: #fff;
    padding: 0;
    cursor: pointer;
  }

  button {
    box-shadow: none;
    border: none;
  }

  input::placeholder {
    color: #6c6c6c;
    opacity: 0.2;
  }
  input::-webkit-input-placeholder {
    color: #6c6c6c;
    opacity: 0.2;
  }
  input:-ms-input-placeholder {
    color: #6c6c6c;
    opacity: 0.2;
  }

  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
`

function Search({
  textValue,
  handlerChangeText,
}: {
  textValue: string
  handlerChangeText: (e: ChangeEvent<HTMLInputElement>) => void
}) {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <Paper
        component='form'
        sx={{
          padding: '7px',
          display: 'flex',
          alignItems: 'center',
          boxShadow: 0,
          background: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <img
          src={Logo}
          alt='logo'
          style={{ marginLeft: '9px' }}
          onClick={() => {
            navigate('/')
            window.location.reload()
          }}
          aria-hidden='true'
        />
        <Divider
          sx={{ height: '18px', m: 1, borderColor: '#D9D9D9', borderRightWidth: 1.5 }}
          orientation='vertical'
        />
        <InputBase
          sx={{ flex: 1 }}
          value={textValue}
          placeholder='Search'
          onChange={handlerChangeText}
          type='text'
          data-testid='search-text-input'
          readOnly
        />
        <button type='button' className='icon_btn'>
          <img src={SearchIcon} alt='search' />
        </button>
      </Paper>
    </Wrapper>
  )
}

export default Search
