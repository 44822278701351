import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import { CaptioningListHeader } from '../components/CaptioningList'
import CaptioningImage from '../components/CaptioningImage'
import { ITag } from '../hooks/useTags'

const Wrapper = styled(Box)`
  height: 100%;
`

const List = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  & div {
    min-width: 130px;
    max-width: 300px;
    width: 25%;
    height: 130px;
  }
`

interface IImageCaptioningViewAllContainer {
  list: ITag[] | undefined
  title: string
}

function ImageCaptioningViewAllContainer({ list, title }: IImageCaptioningViewAllContainer) {
  return (
    <Wrapper>
      <CaptioningListHeader>
        <Typography>{title}</Typography>
      </CaptioningListHeader>
      <List>
        {list?.map((li) => (
          <CaptioningImage
            title={li.tag ?? ''}
            src={li.thumbnailUrl ?? ''}
            key={li.tag}
            type={title ?? ''}
          />
        ))}
      </List>
    </Wrapper>
  )
}

export default ImageCaptioningViewAllContainer
