import apis from '../service/apis'
import useUploadProcessTime from '../states/useUploadProcessTime'

const useUpload = (userId: string, fileList: FileList | null) => async () => {
  const formData = new FormData()
  // const { uploadItems, uploadItem } = useUploadProcessTime.getState()

  if (fileList !== null) {
    for (let i = 0; i < fileList.length; i += 1) {
      formData.append('media', fileList[i])
    }
  }

  const config = {
    onUploadProgress: (progressEvent: ProgressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      useUploadProcessTime.setState({
        uploadItem: {
          uuid: window.crypto.randomUUID(),
          time: percentCompleted,
          fileLength: fileList?.length,
        },
      })
      useUploadProcessTime.setState((state) => ({
        uploadItems: [...state.uploadItems, state.uploadItem],
      }))
    },
  }

  const response = await apis.post(`/api/v1/media?userId=${userId}`, formData, config)

  if (response.status === 201) {
    alert('업로드가 완료 되었습니다.')
  } else if (response.status === 400) {
    alert('파일 확장자를 확인해주세요.')
  } else {
    alert('업로드가 되지 않았습니다. 다시 시도해주세요.')
  }
  return response
}

export default useUpload
