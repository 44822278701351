import React from 'react'
import { Skeleton, styled } from '@mui/material'
import Video from './Video'
import useFullScreen from '../states/useFullScreen'

const Wrapper = styled('div')<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  transition-duration: 1s;

  overflow: hidden;

  img {
    max-width: 600px;
    height: auto;
    max-height: 400px;
  }
`

interface IMiddleSizeImage {
  src: string | undefined
  type: 'video' | 'image' | undefined
  isLoading: boolean
  setFalse: () => void
}

const MiddleSizeImage = React.memo(({ src, type, isLoading, setFalse }: IMiddleSizeImage) => {
  const { setIsFullToggle } = useFullScreen()

  return (
    <Wrapper
      onClick={type === 'video' ? undefined : setIsFullToggle}
      data-testid='middle-size-image-wrapper-in-ImageList'
      isOpen={src !== undefined}
    >
      {isLoading && (
        <Skeleton width='100%' height='400px' sx={{ display: isLoading ? 'block' : 'none' }} />
      )}
      {type === 'image' && src !== undefined && (
        <img
          style={{ display: !isLoading ? 'block' : 'none' }}
          src={src}
          onLoad={() => {
            setFalse()
          }}
          alt='middle size'
          data-testid='middle-size-image-in-ImageList'
        />
      )}

      {type === 'video' && <Video setFalse={setFalse} isLoading={isLoading} />}
    </Wrapper>
  )
})

export default MiddleSizeImage
