import React from 'react'
import Map from '../components/Map'
import Header from '../components/Header'
import ImageListContainer from '../containers/ImageListContainer'
import '../config/styles/Font.css'
import useFullScreen from '../states/useFullScreen'
import FullScreen from '../components/FullScreen'
import useSelectedImage from '../states/useSelectedImage'

function Share() {
  const { isFull, setIsFullToggle } = useFullScreen()
  const selectedSrc = useSelectedImage((state) => state.selectedSrc)

  const handlerClickFullScreen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setIsFullToggle()
  }
  return (
    <>
      <Header />
      <ImageListContainer />
      <Map />
      {isFull && selectedSrc !== undefined && (
        <FullScreen src={selectedSrc} handlerClickFullScreen={handlerClickFullScreen} />
      )}
    </>
  )
}

export default Share
