import { Box, Button as MuiButton, CircularProgress, styled } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Arrow from '../assets/image/arrow-down.svg'
import Share from '../assets/image/share.svg'
import ArrowLeft from '../assets/image/arrow-left.svg'
import useSelectedClusters from '../states/useSelectedClusters'
import useSelectedImage from '../states/useSelectedImage'
import TrashBin from '../assets/image/trashbin.svg'
import useSelectedImageList, { ISelectedList } from '../states/useSelectedImageList'
import apis from '../service/apis'
import useShareLink from '../hooks/useShareLink'
import ShareDialog from '../components/ShareDialog'
import useDialogState from '../states/useDialogState'
import { IReqListType } from '../types/reqList'

const Wrapper = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;

  & .MuiButton-root {
    height: 100%;
  }
`

const Button = styled(MuiButton)`
  border: 1px solid #d6d6d6;
  background-color: #fff;
  padding: 6px 14px;
`

interface IShareDownloadContainer {
  isCaptioning: boolean
}

function ShareDownloadContainer({ isCaptioning }: IShareDownloadContainer) {
  const [queryParameters] = useSearchParams()
  const { toggleSelectedClusters, selectedClusters } = useSelectedClusters()
  const { toggleSelectedSrc } = useSelectedImage()
  const { list, clearList } = useSelectedImageList()
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const { mutate, data: shareData, isLoading } = useMutation(useShareLink)
  const { isShareLinkOpen } = useDialogState()

  const convertList = (_list: ISelectedList) => {
    const convertData: IReqListType[] = []
    _list.forEach((mediaType, mediaId) => {
      convertData.push({ mediaId, type: mediaType })
    })
    return convertData
  }

  const deleteMutation = useMutation({
    mutationFn: async (_list: ISelectedList) =>
      apis.delete<{ mediaList: IReqListType[] }>('api/v1/media', { mediaList: convertList(_list) }),
    onSuccess: () => {
      const temp = { ...selectedClusters }
      Object.keys(temp).forEach((key) => {
        temp[key] = temp[key].filter((data) => !list.has(data.mediaId))
      })

      toggleSelectedClusters(temp)
      clearList()
      void queryClient.invalidateQueries('getImageData')
    },
  })

  // const shareMutation = useMutation({
  //   mutationFn: async (_list: ISelectedList) =>
  //     axios.post<{ shareMedia: IReqListType[] }>('https://d-drive.api.innopam.kr/api/v1/link', {
  //       shareMedia: convertList(_list),
  //     }),
  // })

  React.useEffect(() => {
    if (shareData !== null && shareData !== undefined) {
      useDialogState.setState({ isShareLinkOpen: true })
    }
  }, [shareData])

  return (
    <>
      <Wrapper>
        <MuiButton
          onClick={() => {
            if (queryParameters.get('view-all') !== null) {
              navigate('/')
              toggleSelectedClusters(undefined)
              return
            }
            if (queryParameters.get('filter') !== null) {
              navigate(`/?type=${queryParameters.get('type') ?? ''}&view-all=true`)
              return
            }
            if (
              queryParameters.get('type') === null &&
              queryParameters.get('filter') === null &&
              selectedClusters !== undefined
            ) {
              toggleSelectedClusters(undefined)
              toggleSelectedSrc(undefined, undefined, undefined, undefined)
            }
          }}
        >
          <img src={ArrowLeft} alt='left direction arrow' />
        </MuiButton>
        {!isCaptioning && (
          <Button
            onClick={() => {
              mutate(convertList(list))
            }}
            startIcon={<img src={Share} alt='profile icon' />}
            disabled={list.size === 0 || location.pathname.includes('share')}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Share'}
          </Button>
        )}
        {!isCaptioning && (
          <Button
            onClick={() => {
              console.log(list)
            }}
            startIcon={<img src={Arrow} alt='profile icon' />}
            disabled
          >
            Download
          </Button>
        )}
        {!isCaptioning && (
          <Button
            onClick={() => {
              deleteMutation.mutate(list)
            }}
            startIcon={<img src={TrashBin} alt='delete icon' />}
            disabled={list.size === 0 || location.pathname.includes('share')}
          >
            {deleteMutation.isLoading ? <CircularProgress size={24} /> : 'Delete'}
          </Button>
        )}
      </Wrapper>
      {shareData !== undefined && Boolean(isShareLinkOpen) && (
        <ShareDialog open={isShareLinkOpen} code={shareData.code} />
      )}
    </>
  )
}

export default ShareDownloadContainer
