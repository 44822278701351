import create from 'zustand'
import { persist } from 'zustand/middleware'

interface IUploadItem {
  uuid: string
  time: number
  fileLength: number | null | undefined
}

interface MediaDataState {
  uploadItem: IUploadItem
  uploadItems: IUploadItem[]
  setUploadProcessTime: (select: IUploadItem) => void
}

const useUploadProcessTime = create<MediaDataState>()(
  persist(
    (set) => ({
      uploadItem: { uuid: '', time: 0, fileLength: null },
      uploadItems: [],
      setUploadProcessTime: (data: IUploadItem) =>
        set((state) => ({
          ...state,
          uploadItem: { uuid: data.uuid, time: data.time, fileLength: data.fileLength },
          uploadItems: [state.uploadItem],
        })),
    }),
    { name: 'user-uploadProcessTime' },
  ),
)

export default useUploadProcessTime
