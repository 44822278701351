import * as React from 'react'
import { styled } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import UploadPopover from './UploadPopover'
import LoadingPopover from './LoadingPopover'
import { top } from '../config/styles/commonStyles'
import CustomPopover from './common/CustomPopover'
import useDialogState from '../states/useDialogState'
import useUserData from '../states/useUserData'

interface IPopover {
  isEvent: boolean
  width: string
  height: string
}

const Button = styled('button')`
  position: fixed;
  right: 68px;
  top: ${top};
  z-index: 500;

  width: 108px;
  background-color: #256197;
  box-shadow: none;
  border: none;
  color: #fff;
  padding: 6px 14px;
  border-radius: 4px;

  text-align: center;
  font-family: 'Noto Sans NKo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

function UploadButton() {
  const upload: IPopover = {
    isEvent: false,
    width: '310px',
    height: '288px',
  }
  const loading: IPopover = {
    isEvent: false,
    width: '280px',
    height: '344px',
  }
  const { isUploadOpen, isLoadingOpen } = useDialogState()
  const { userData } = useUserData()

  return (
    <>
      <Button
        type='button'
        onClick={() =>
          userData.userName !== ''
            ? useDialogState.setState({
                isProfileOpen: false,
                isLoadingOpen: false,
                isUploadOpen: !isUploadOpen,
              })
            : window.alert('Please login.')
        }
        onMouseEnter={() =>
          useDialogState.setState({
            isLoadingOpen: false,
            isUploadOpen: false,
            isProfileOpen: false,
          })
        }
      >
        <ArrowUpwardIcon sx={{ color: '#fff', marginRight: '4px' }} />
        <span>Upload</span>
      </Button>
      <CustomPopover
        visible={isUploadOpen || isLoadingOpen}
        width={isUploadOpen ? upload.width : loading.width}
        height={isUploadOpen ? upload.height : loading.height}
        isShadow={isUploadOpen && !upload.isEvent}
        right='68px'
      >
        {isUploadOpen && (
          <UploadPopover handleClose={() => useDialogState.setState({ isUploadOpen: false })} />
        )}
        {isLoadingOpen && !isUploadOpen && <LoadingPopover />}
      </CustomPopover>
    </>
  )
}

export default UploadButton
