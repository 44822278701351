import { styled } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import CustomPopover from './common/CustomPopover'
import useMeta from '../hooks/useMeta'
import useSelectedImage from '../states/useSelectedImage'

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  font-family: 'Noto Sans NKo', sans-serif;
  font-style: normal;
  line-height: 100%;
  font-weight: 400;
  padding: 20px 20px 40px 40px;
  background: rgba(0, 0, 0, 0.8);

  .header {
    display: flex;
    justify-content: flex-end;
  }

  .icon {
    color: #fff;
    width: 21px;
    height: 21px;
    cursor: pointer;
  }

  .item__box {
    display: flex;
    flex-direction: row;

    :not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .item__key {
    font-size: 18px;
    color: #b5b5b5;
    width: 164px;
  }

  .item__value {
    font-size: 18px;
    color: #ffffff;
  }
`

function MetaDataPopover({
  isVisible,
  handleClose,
}: {
  isVisible: boolean
  handleClose: () => void
}) {
  const { selectedSrcUUID, selectedSrcType } = useSelectedImage((state) => state)
  const { data: metaData } = useMeta(selectedSrcUUID as string, selectedSrcType as string)
  const convertLatLng = (position: number) => {
    const d = Math.floor(position)
    const m = Math.floor((position % d) * 60)
    const s = ((position % d) * 60 - m) * 60
    return `${d}˚ ${m}'  ${s.toFixed(2)}"`
  }

  return (
    <CustomPopover
      height='fit-content'
      isShadow={false}
      right='0px'
      visible={isVisible}
      width='579px'
    >
      <Wrapper>
        <div className='header'>
          <CloseIcon className='icon' onClick={() => handleClose()} />
        </div>
        <div className='item__box'>
          <span className='item__key'>Make</span>
          <span className='item__value'>{metaData?.make ?? ''}</span>
        </div>
        <div className='item__box'>
          <span className='item__key'>Camera Model</span>
          <span className='item__value'>{metaData?.model ?? ''}</span>
        </div>
        <div className='item__box'>
          <span className='item__key'>Software</span>
          <span className='item__value'>{metaData?.softwareModel ?? ''}</span>
        </div>
        <div className='item__box'>
          <span className='item__key'>Date/Time</span>
          <span className='item__value'>{metaData?.imageCreateDate ?? ''}</span>
        </div>

        <div className='item__box'>
          <span className='item__key'>Focal Length</span>
          <span className='item__value'>{metaData?.focalLength ?? ''}</span>
        </div>
        <div className='item__box'>
          <span className='item__key'>Exposure time</span>
          <span className='item__value'>{metaData?.exposureTime ?? ''}</span>
        </div>
        <div className='item__box'>
          <span className='item__key'>Aperture</span>
          <span className='item__value'>{metaData?.aperture ?? ''}</span>
        </div>
        <div className='item__box'>
          <span className='item__key'>ISO</span>
          <span className='item__value'>{metaData?.iso ?? ''}</span>
        </div>
        <div className='item__box'>
          <span className='item__key'>GPS</span>
          <span className='item__value'>
            N {convertLatLng(metaData?.latitude ?? 0)} , E {convertLatLng(metaData?.longitude ?? 0)}
          </span>
        </div>
      </Wrapper>
    </CustomPopover>
  )
}

export default MetaDataPopover
