import { useQuery } from 'react-query'
import apis from '../service/apis'

interface Igeotagging {
  timestamp: number
  latitude: number
  longitude: number
  distance: number
  rel_elevation: number
  horizontal_velocity: number
  vertical_velocity: number
}

interface IVideoPosition {
  geotagging: Igeotagging[]
}

const useVideoPosition = (uuid: string | undefined) =>
  useQuery<IVideoPosition, Error, Array<[number, number]>>(
    ['getVideoPosition', uuid],
    async () => apis.get<{}, IVideoPosition>(`/api/v1/media/drone-fly-path?mediaId=${uuid ?? ''}`),
    {
      select: (data) => data.geotagging.map((position) => [position.latitude, position.longitude]),
      enabled: uuid !== undefined,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24,
    },
  )

export default useVideoPosition
