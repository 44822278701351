import React, { useRef } from 'react'
import { styled } from '@mui/material'

const Wrapper = styled('div')<{ percentage: number }>`
  height: 8px;
  width: 100%;

  .time-progressbarContainer {
    background-color: gray;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    z-index: 30;
    position: relative;
  }

  .time-progressBar {
    border-radius: 15px;
    background-color: #fff;
    height: 100%;
    width: ${({ percentage }) => percentage}%;
  }
`
const TimeStamp = styled('div')<{ time: number }>`
  position: absolute;
  top: 0;
  left: ${({ time }) => time}%;
  width: 4px;
  height: 100%;
  background: red;
  cursor: pointer;
`

interface IVideoProgressBar {
  percentage: number
  videoTime: number
  handlerMoveTimeStamp: (time: number) => () => Promise<void>
}

function VideoProgressBar({ percentage, handlerMoveTimeStamp, videoTime }: IVideoProgressBar) {
  const progressbarRef = useRef<HTMLDivElement>(null)

  return (
    <Wrapper percentage={percentage}>
      <div
        ref={progressbarRef}
        role='presentation'
        className='time-progressbarContainer'
        onClick={(e) => {
          const skipTo = Math.round(
            ((e.clientX - e.currentTarget.getBoundingClientRect().left) /
              e.currentTarget.getBoundingClientRect().width) *
              parseInt(String(videoTime), 10),
          )
          void handlerMoveTimeStamp(skipTo)()
        }}
      >
        <div className='time-progressBar' />
        {[20, 40, 60, 80].map((time) => (
          <TimeStamp
            role='presentation'
            /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
            onClick={handlerMoveTimeStamp(time)}
            time={time}
            key={time}
          />
        ))}
      </div>
    </Wrapper>
  )
}

export default VideoProgressBar
