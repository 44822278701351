import { useQuery } from 'react-query'
import { LatLngExpression } from 'leaflet'
import apis from '../service/apis'
import { IDto } from '../types/Dto'

const useLocation = (type: boolean) =>
  useQuery<IDto<{ type: string; coordinates: [number, number] }>, Error, LatLngExpression>(
    ['getLocation'],
    async () => {
      if (type) {
        return apis.get<{}, IDto<{ type: string; coordinates: [number, number] }>>(
          '/api/v1/search/latest',
        )
      }
      return apis.get<{}, IDto<{ type: string; coordinates: [number, number] }>>(
        '/api/v1/search/latest',
      )
    },
    {
      select: (data) => [data.data.coordinates[1], data.data.coordinates[0]] as LatLngExpression,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24,
    },
  )

export default useLocation
