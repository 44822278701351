import React from 'react'
import { styled } from '@mui/material'
import { useMutation } from 'react-query'
import LogoImg from '../assets/image/logo-big.svg'
import GoogleLoginButton from '../assets/image/GoogleLoginButton.png'
import useLogin from '../hooks/useLogin'

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .text {
    font-family: 'Days One';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: #b5b5b5;
  }

  button {
    margin-top: 238px;
    margin-bottom: 131px;
    border: none;
    padding: 0;
    box-shadow: none;
    background: transparent;
    cursor: pointer;
  }

  img {
    width: 239px;
    height: 58px;
  }
`

function Login() {
  const { mutate, data: url } = useMutation(useLogin)

  React.useEffect(() => {
    if (url !== undefined && window !== undefined) {
      window.location.href = url.authorizeUrl
    }
  }, [url])

  return (
    <Wrapper>
      <img src={LogoImg} alt='logo' />
      <button
        type='button'
        onClick={() => {
          mutate()
        }}
      >
        <img src={GoogleLoginButton} alt='Sign In with Google' />
      </button>
      <span className='text'>copyright@innopam</span>
    </Wrapper>
  )
}

export default Login
