import { Box } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import ShareDownloadContainer from './ShareDownloadContainer'
import ImageFilter from '../components/ImageFilter'
import { top } from '../config/styles/commonStyles'

const Wrapper = styled(Box)`
  padding-top: ${top};

  & .filter-container {
    margin: 24px 0;
  }
`

interface IListHeaderContainer {
  isCaptioning: boolean
}

/**
 * @params isCaptioning : check captioning List
 */
const ListHeaderContainer = React.memo(({ isCaptioning }: IListHeaderContainer) => (
  <Wrapper>
    <ShareDownloadContainer isCaptioning={isCaptioning} />
    <div className='filter-container'>{!isCaptioning && <ImageFilter />}</div>
  </Wrapper>
))

export default ListHeaderContainer
