import create from 'zustand'
import { devtools } from 'zustand/middleware'
import React from 'react'

interface IUseVideoTime {
  videoRef: null | React.RefObject<HTMLVideoElement>
  currentTime: number
  videoTime: number
  isStarting: boolean
  setCurrentTime: (t: number) => void
  setVideoTime: (t: number) => void
  setVideoStarting: () => void
  setVideoStop: () => void
  setVideoToggle: (value: boolean) => void
  setVideoRef: (ref: React.RefObject<HTMLVideoElement>) => void
}

const useVideoTime = create<IUseVideoTime>()(
  devtools((set) => ({
    videoRef: null,
    currentTime: 0,
    videoTime: 0,
    isStarting: false,
    setCurrentTime: (t: number) => set((prev) => ({ ...prev, currentTime: t })),
    setVideoTime: (t: number) => set((prev) => ({ ...prev, videoTime: t })),
    setVideoStarting: () => set((prev) => ({ ...prev, isStarting: true })),
    setVideoStop: () => set((prev) => ({ ...prev, isStarting: false })),
    setVideoToggle: (value: boolean) => set((prev) => ({ ...prev, isStarting: value })),
    setVideoRef: (videoRef) => set((prev) => ({ ...prev, videoRef })),
  })),
)
export default useVideoTime
