import { styled } from '@mui/material'
import React from 'react'
import GoogleDriveIcon from '../assets/image/google-drive-icon.svg'

const Wrapper = styled('div')`
  font-family: 'Noto Sans NKo', sans-serif;
  font-style: normal;
  line-height: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #707070;

  cursor: pointer;

  .icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .upload__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #efefef;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 12px;
  }

  input[type='file'] {
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
`

function GoogleDriveUploadButton() {
  return (
    <Wrapper>
      <button data-testid='googleDriveButton' type='button' className='upload__btn'>
        <img src={GoogleDriveIcon} alt='google drive' />
      </button>
      <span className='btn__title'>Google Drive</span>
    </Wrapper>
  )
}

export default GoogleDriveUploadButton
