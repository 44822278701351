import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { IMedia } from '../types/medias'

export type IImageData = Record<string, IMedia[]>
export type IToggleSelectedClusters = (clustering: IImageData | undefined) => void

interface IUseSelectedImageList {
  selectedClusters: IImageData | undefined
  toggleSelectedClusters: IToggleSelectedClusters
}

const useSelectedClusters = create<IUseSelectedImageList>()(
  devtools((set) => ({
    selectedClusters: undefined,
    toggleSelectedClusters: (clustering: IImageData | undefined) =>
      set(() => ({ selectedClusters: clustering })),
  })),
)

export default useSelectedClusters
