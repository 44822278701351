import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import apis from '../service/apis'
import { IDto } from '../types/Dto'

interface IQuery {
  tagType?: string
  limit?: number
}

export interface ITag {
    tag: string
    cnt: number
    thumbnailUrl: string

}

export type TTags = "placeTagData" | "timeTagData" | "objectTagData"
type ITags = Record<TTags, ITag[]>

const useTags = (textValue: string, query?: IQuery) =>
    useQuery<IDto<ITags>, AxiosError, ITags>(
        ['getTagsData', textValue, query],
        async () => apis.get<IQuery, IDto<ITags>>(`/api/v1/search/tag`, query),
        {
            select: (data) => data.data,
        },
    )

export default useTags
