import React from 'react'
import { styled } from '@mui/material'
import { top } from '../config/styles/commonStyles'
import ProfilePopover from './ProfilePopover'
import ProfileImage from '../assets/image/profile.svg'
import useDialogState from '../states/useDialogState'
import useUserData from '../states/useUserData'

const Wrapper = styled('div')`
  background-color: #256197;
  width: 34px;
  height: 34px;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: ${top};
  z-index: 500;
  filter: drop-shadow(0px 0px 28px rgba(37, 96, 151, 0.25));
  left: auto;
  position: fixed;
  right: 20px;

  .image {
    width: 25px;
    height: 25px;
  }

  .profileImage {
    background-color: #fff;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    object-fit: contain;
  }
`

function ProfileButton() {
  const { isProfileOpen } = useDialogState()
  const { userData } = useUserData()

  return (
    <>
      <Wrapper
        onClick={() =>
          useDialogState.setState({
            isProfileOpen: !isProfileOpen,
          })
        }
      >
        <img
          src={userData.userProfilePicture !== '' ? userData.userProfilePicture : ProfileImage}
          alt='profile icon'
          className={userData.userProfilePicture !== '' ? 'profileImage' : 'image'}
          referrerPolicy='no-referrer'
        />
      </Wrapper>
      <ProfilePopover isVisible={isProfileOpen} />
    </>
  )
}

export default ProfileButton
