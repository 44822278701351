import axios from 'axios'
import useUserData from '../states/useUserData'

export const url = 'https://d-drive.api.innopam.kr'

const api = axios.create({
  baseURL: url,
  headers: {
    widthCredentials: true,
    'Content-Type': 'multipart/form-data',
    Authorization: `${useUserData.getState().userData.accessToken}`,
  },
})

const apis = {
  get: async <IParams, IRes>(path: string, params?: IParams): Promise<IRes> =>
    api.get(path, { params: { ...params } }).then((res) => res.data),
  post: async <IBodyType>(path: string, body: IBodyType, config?: object) =>
    api.post(path, body, config),
  put: async <DataType>(path: string, data?: DataType) => api.put(path, data),
  patch: async <DataType>(path: string, data?: DataType) => api.patch(path, data),
  delete: async <DataType>(path: string, data: DataType) => api.delete(path, { data }),
}

export default apis
