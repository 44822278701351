import React, { useState } from 'react'
import L from 'leaflet'
import { LayersControl, MapContainer as MC, Marker, TileLayer } from 'react-leaflet'
import { styled } from '@mui/material'
import { useLocation as hLocation } from 'react-router-dom'
import VideoPolyLine from './VideoPolyLine'
import useSelectedImage from '../states/useSelectedImage'
import MakerClusters from './MakerClusters'
import useLocation from '../hooks/useLocation'
import useExternalMap from '../states/useExternalMap'
import useDialogState from '../states/useDialogState'
import LayerIcon from '../assets/image/layersIcon.png'
import MarkerIcon from '../assets/image/marker-icon.svg'

const MapContainer = styled(MC)`
  height: 100vh;
  width: 100vw;
  position: relative;

  .marker-cluster {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .marker-cluster div {
    display: inline-block;
    border-radius: 50%;
    min-width: 40px;
    min-height: 40px;
    padding: 5px;
    background: red;
    color: white;
    text-align: center;
    line-height: 1;
    box-sizing: content-box;
    white-space: nowrap;
  }

  .marker-cluster div:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
    height: 0;
  }

  .marker-cluster span {
    display: inline-block;
    vertical-align: middle;
  }

  .marker-cluster.small {
    background-color: rgba(214, 179, 0, 0.36);
  }

  .marker-cluster.small.selected div {
    border: 1rem solid darkred;
  }

  .marker-cluster.small div {
    background-color: #d6b300;
    font-size: 16px;
  }

  .marker-cluster.medium {
    background-color: rgba(214, 77, 0, 0.36);
  }

  .marker-cluster.medium div {
    background-color: #d64d00;
    font-size: 24px;
  }

  .marker-cluster.large {
    background-color: rgba(84, 14, 173, 0.36);
  }

  .marker-cluster.large div {
    background-color: rgba(84, 14, 173, 0.8);
    font-size: 24px;
  }

  .marker-cluster.selected {
    background-color: red;
  }

  .leaflet-cluster-anim .leaflet-marker-icon,
  .leaflet-cluster-anim .leaflet-marker-shadow {
    -webkit-transition: -webkit-transform 0.6s ease-out, opacity 0.6s ease-in;
    -moz-transition: -moz-transform 0.6s ease-out, opacity 0.6s ease-in;
    -o-transition: -o-transform 0.6s ease-out, opacity 0.6s ease-in;
    transition: transform 0.6s ease-out, opacity 0.6s ease-in;
  }

  .drone-icon {
    background: transparent;
    border-width: 0;
  }

  .leaflet-marker-icon {
    -webkit-transition: -webkit-transform 1s ease-out, opacity 1s ease-in;
    -moz-transition: -moz-transform 1s ease-out, opacity 1s ease-in;
    -o-transition: -o-transform 1s ease-out, opacity 1s ease-in;
    transition: transform 1s ease-out, opacity 1s ease-in;
  }

  .leaflet-control {
    visibility: hidden;
    border: none;
    color: white;
  }

  .layer-control {
    position: absolute;
    z-index: 999;
    bottom: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    background: #256197;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    cursor: pointer;

    &.not_selected {
      background: #fff;
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.6);
    }
  }

  button {
    border: none;
  }

  .test {
    stroke: blue;
    fill: yellow;
  }
`

export default function Map() {
  const location = hLocation()

  const { data, isLoading, isError } = useLocation(location.pathname.includes('share'))
  const setMap = useExternalMap((state) => state.setMap)
  const selectedSrcType = useSelectedImage((state) => state.selectedSrcType)
  const [isOSM, setIsOSM] = useState(true)
  const { selectedGeom } = useSelectedImage()

  const handleLayerControl = () => {
    setIsOSM(!isOSM)
  }

  React.useEffect(() => {}, [selectedGeom])

  const customIcon = L.icon({
    iconUrl: MarkerIcon,
    iconSize: [30, 50],
  })

  if (isLoading) return <div>loading...</div>
  if (isError) return <div>there is error</div>
  return (
    <div
      onClick={() => {
        useDialogState.setState({ isProfileOpen: false, isUploadOpen: false, isLoadingOpen: false })
      }}
      aria-hidden='true'
    >
      <MapContainer center={data} zoom={18} zoomControl={false} ref={setMap}>
        <button
          type='button'
          className={`layer-control ${isOSM ? '' : 'not_selected'}`}
          onClick={handleLayerControl}
        >
          <img src={LayerIcon} alt='control layer' />
        </button>
        <LayersControl position='bottomleft'>
          <LayersControl.BaseLayer checked={isOSM} name='OSM'>
            <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' maxZoom={19} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer checked={!isOSM} name='MQI'>
            <TileLayer
              url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
              maxZoom={19}
              subdomains={['mt1', 'mt2', 'mt3']}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <MakerClusters />
        {selectedGeom !== undefined && selectedSrcType !== 'video' && (
          <Marker
            position={[selectedGeom.coordinates[1], selectedGeom.coordinates[0]]}
            icon={customIcon}
            zIndexOffset={100}
          />
        )}
        {selectedSrcType === 'video' && <VideoPolyLine />}
      </MapContainer>
    </div>
  )
}
