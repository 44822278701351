import React from 'react'
import { Dialog, IconButton, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useDialogState from '../states/useDialogState'

interface IDialog {
  open: boolean
  code: string
}

const Wrapper = styled('div')`
  display: flex;
  width: 392px;
  flex-direction: column;
  padding: 30px 16px;
  font-family: 'Noto Sans';
  font-style: normal;
  line-height: 100%;

  .header {
    font-weight: 400;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #101820;
  }

  .icon__btn {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #f4f4f4;
    color: #4b4b4b;
  }

  .icon {
    width: 16px;
    height: 16px;
  }

  .contents {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 8px;
  }

  .description {
    font-weight: 600;
    font-size: 14px;
    color: #666666;
  }

  .link {
    display: inline-block;
    white-space: nowrap;
    background: #ebebeb;
    border-radius: 4px;
    width: 100%;
    height: 36px;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    color: #828282;
    padding: 12px;
    overflow-x: scroll;
    overflow: -moz-scrollbars-horizontal;
    -ms-overflow-style: none; /* IE and Edge */
  }

  .link::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .copy__btn {
    width: 100%;
    height: 36px;
    background: #256197;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    box-shadow: none;
    border: none;
    cursor: pointer;
  }
`

function ShareDialog({ open, code }: IDialog) {
  const restDay: number = 7

  const handleCopy = () => {
    window.navigator.clipboard.writeText(`https://d-drive.innopam.kr/share/${code}`)
  }

  const handleClose = () => {
    useDialogState.setState({ isShareLinkOpen: false })
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Wrapper>
        <div className='header'>
          <span>Share</span>
          <IconButton onClick={handleClose} className='icon__btn'>
            <CloseIcon className='icon' />
          </IconButton>
        </div>
        <div className='contents'>
          <span className='description'>Page Link valid for {restDay} days.</span>
          <span className='link'>https://d-drive.innopam.kr/share/{code}</span>
          <button type='button' className='copy__btn' onClick={handleCopy}>
            Copy URL
          </button>
        </div>
      </Wrapper>
    </Dialog>
  )
}
export default ShareDialog
