import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Drawer, styled } from '@mui/material'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import useSelectedImageList from '../states/useSelectedImageList'
import { IMedia } from '../types/medias'
import ImageCard from './ImageCard'
import { IImageData } from '../states/useSelectedClusters'
import MiddleSizeImage from './MiddleSizeImage'
import useSelectedImage from '../states/useSelectedImage'
import useBoolean from '../hooks/useBoolean'

const Wrapper = styled('div')`
  overflow-y: scroll;
  height: 90vh;
  position: relative;

  & .images {
    margin-bottom: 40px;

    .images-date-title {
      margin-bottom: 10px;
    }
  }
`
export const DataWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`

const EmptyWrapper = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
`

interface IImageList {
  selectedClusters: IImageData | undefined
}

function ImageList({ selectedClusters }: IImageList) {
  const [keys, setKeys] = useState<string[]>([])
  const selectedThumb = useSelectedImage((state) => state.selectedThumb)
  const selectedSrcType = useSelectedImage((state) => state.selectedSrcType)

  const list = useSelectedImageList((state) => state.list)
  const { value: isLoading, setFalse, setTrue } = useBoolean(true)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handlerMoveTop = useCallback(() => {
    if (wrapperRef.current === null) return
    if (wrapperRef.current.getBoundingClientRect().top === 0) return

    wrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' })
  }, [wrapperRef])

  useEffect(() => {
    if (selectedClusters === undefined) return

    setKeys(Object.keys(selectedClusters))
  }, [selectedClusters])

  if (selectedClusters === undefined)
    return (
      <EmptyWrapper>
        <div className='empty-text'>
          <ImageOutlinedIcon />
          <div>There is no images</div>
        </div>
      </EmptyWrapper>
    )

  return (
    <Wrapper ref={wrapperRef}>
      <MiddleSizeImage
        src={selectedThumb}
        type={selectedSrcType}
        isLoading={isLoading}
        setFalse={setFalse}
      />
      {keys.map((key) => (
        <div className='images' key={key}>
          <div className='images-date-title'>{key}</div>
          <DataWrapper data-testid='responsible-image-list-container'>
            {selectedClusters?.[key]?.map((image: IMedia) => (
              <ImageCard
                isSelected={list.has(image.mediaId)}
                src={image.originUrl}
                thumb={image.thumbnailUrl}
                title={image.viewFileName}
                key={image.mediaId}
                type={image.type}
                mediaId={image.mediaId}
                coordinates={image.geom.coordinates}
                geom={image.geom}
                handlerSetLoading={setTrue}
                handlerMoveTop={handlerMoveTop}
              />
            ))}
          </DataWrapper>
          <Drawer />
        </div>
      ))}
    </Wrapper>
  )
}

export default ImageList
