import create from 'zustand'
import { devtools } from 'zustand/middleware'

interface IUseSelectedImageList {
  drawerWidth: number
  setDrawerWidth: (newWidth: number) => void
}

export const initialWidth = 630

const useDrawerWidth = create<IUseSelectedImageList>()(
  devtools((set) => ({
    drawerWidth: initialWidth,
    setDrawerWidth: (newWidth: number) => set(() => ({ drawerWidth: newWidth })),
  })),
)

export default useDrawerWidth
