import L, { LeafletEvent, MarkerCluster } from 'leaflet'
import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import 'leaflet.markercluster'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import useImageData from '../hooks/useImageData'
import useSelectedClusters, { IImageData } from '../states/useSelectedClusters'
import { IMedia } from '../types/medias'
import useSelectedImage from '../states/useSelectedImage'
import useDrawerWidth, { initialWidth } from '../states/useDrawerWidth'

function MakerClusters() {
  const [queryParameters] = useSearchParams()
  const type = queryParameters.get('type')
  const filter = queryParameters.get('filter')
  const location = useLocation()
  const {
    isLoading,
    data: images,
    isError,
  } = useImageData(
    '',
    type !== null ? { [type.toLowerCase()]: filter ?? '' } : undefined,
    location.pathname.includes('share')
      ? `/api/v1/link/${location.pathname.split('/share/')[1]}`
      : '/api/v1/search/media',
  )
  const { toggleSelectedClusters } = useSelectedClusters()
  const map = useMap()
  const { toggleSelectedSrc } = useSelectedImage()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const initialSize = 60
  const { drawerWidth, setDrawerWidth } = useDrawerWidth()

  const resetParams = (deleteParams: string) => {
    searchParams.delete(deleteParams)
    navigate(`/?${searchParams.toString()}`)
  }

  const createClusterCustomIcon = function (cluster: MarkerCluster) {
    const cnt = cluster.getChildCount()
    let clusterBorder = initialSize
    let className = ''
    if (cnt < 10) {
      className = 'small'
    } else if (cnt < 100) {
      className = 'medium'
      clusterBorder = 60
    } else {
      className = 'large'
      clusterBorder = 80
    }

    return L.divIcon({
      html: `<div><span>${cnt > 100 ? '100+' : String(cnt)}</span></div>`,
      className: `marker-cluster ${className}`,
      iconSize: L.point(clusterBorder, clusterBorder),
    })
  }

  const convertDate = (clusterInfo: IMedia) => {
    const splitDate = new Date(clusterInfo.mediaCreateDate).toDateString().split(' ')
    return `${splitDate[2]} ${splitDate[1]} ${splitDate[3]}`
  }

  useEffect(() => {
    if (location.pathname.includes('share') && images !== undefined) {
      const imageList = images.reduce((acc: IImageData, clusterInfo) => {
        const date = convertDate(clusterInfo)
        acc[date] = acc[date] === undefined ? [clusterInfo] : [...acc[date], clusterInfo]
        return acc
      }, {})
      toggleSelectedClusters(imageList)
    }
  }, [toggleSelectedClusters, location.pathname, images])

  useEffect(() => {
    const markers = L.markerClusterGroup({
      animate: true,
      iconCreateFunction: createClusterCustomIcon,
      zoomToBoundsOnClick: false,
      spiderfyOnMaxZoom: false,
    })

    const setNewSelectedClusters = (clusters: IImageData) => {
      resetParams('view-all')
      toggleSelectedSrc(undefined, undefined, undefined, undefined)
      toggleSelectedClusters(clusters)
    }

    const handlerSelectedClusters = (e: LeafletEvent) => {
      // markers.refreshClusters()
      // const cnt = e.layer.getChildCount()
      // let clusterBorder = initialSize
      // let className = ''
      // if (cnt < 10) {
      //   className = 'small'
      // } else if (cnt < 100) {
      //   className = 'medium'
      //   clusterBorder = 60
      // } else {
      //   className = 'large'
      //   clusterBorder = 80
      // }
      //
      // const newIcon = L.divIcon({
      //   html: `<div><span>${cnt > 100 ? '100+' : String(cnt)}</span></div>`,
      //   className: `marker-cluster selected ${className}`,
      //   iconSize: L.point(clusterBorder, clusterBorder),
      // })
      //
      // e.layer.setIcon(newIcon)

      const clusters: MarkerCluster[] = e.layer.getAllChildMarkers()
      const imageList = clusters.reduce((acc: IImageData, cur) => {
        if (cur.options.alt === undefined) return acc
        const clusterInfo: IMedia = JSON.parse(cur.options.alt)
        const date = convertDate(clusterInfo)
        acc[date] = acc[date] === undefined ? [clusterInfo] : [...acc[date], clusterInfo]
        return acc
      }, {})

      e.layer.zoomToBounds({ padding: [20, 20] })
      if (drawerWidth === 0) {
        setDrawerWidth(initialWidth + 1)
      }

      setNewSelectedClusters(imageList)
    }
    markers.on('clusterclick', handlerSelectedClusters)

    if (type !== null && filter !== null && images !== undefined) {
      const imageList = images.reduce((acc: IImageData, clusterInfo) => {
        const date = convertDate(clusterInfo)
        acc[date] = acc[date] === undefined ? [clusterInfo] : [...acc[date], clusterInfo]
        return acc
      }, {})
      setNewSelectedClusters(imageList)
    }

    const handlerReset = () => {
      map.removeLayer(markers)
      markers.off('clusterclick', handlerSelectedClusters)
      if (location.pathname.includes('share')) {
        navigate(location.pathname)
      }
    }

    if (isLoading || isError || images === undefined) {
      return () => {
        handlerReset()
      }
    }

    const initialClusters: IImageData = {}
    images.forEach((cluster) => {
      const marker = L.marker([cluster.geom.coordinates[1], cluster.geom.coordinates[0]], {
        icon: L.divIcon({
          html: `<div><span>1</span></div>`,
          className: 'marker-cluster small',
          iconSize: L.point(initialSize, initialSize),
        }),
      })

      marker.options.alt = JSON.stringify(cluster)
      marker.on('click', () => {
        const date = convertDate(cluster)
        if (drawerWidth === 0) {
          setDrawerWidth(initialWidth + 1)
        }
        setNewSelectedClusters({ [date]: [cluster] })
      })
      markers.addLayer(marker)
      const date = convertDate(cluster)
      initialClusters[date] =
        initialClusters[date] === undefined ? [cluster] : [...initialClusters[date], cluster]
    })

    map.addLayer(markers)

    return () => {
      handlerReset()
    }
    // eslint-disable-next-line
  }, [map, images, drawerWidth])
  return null
}

export default MakerClusters
