import * as React from 'react'
import styled from '@emotion/styled'

interface ICustomPopover {
  visible: boolean
  children: React.ReactNode
  width: string
  height: string
  isShadow: boolean
  right: string
}

const Popover = styled('div')<{
  visible: boolean
  width: string
  height: string
  isShadow: boolean
  right: string
}>`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: absolute;
  top: 65px;
  bottom: 0;
  right: ${(props) => props.right};
  background-color: transparent;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow-y: scroll;
  z-index: 900;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  filter: ${(props) =>
    props.isShadow ? 'drop-shadow(0px 0px 28px rgba(37, 96, 151, 0.25))' : 'none'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

export default function CustomPopover({
  children,
  width,
  height,
  visible,
  isShadow = false,
  right,
}: ICustomPopover) {
  return (
    <Popover visible={visible} width={width} height={height} isShadow={isShadow} right={right}>
      {children}
    </Popover>
  )
}
