import { AxiosResponse } from 'axios'
import apis from '../service/apis'

async function useLogin(): Promise<{ authorizeUrl: string }> {
  const { data }: AxiosResponse<{ authorizeUrl: string }> = await apis.post(`/api/v1/oauth`, {})

  return data
}

export default useLogin
