import { devtools } from 'zustand/middleware'
import create from 'zustand'
import L from 'leaflet'

interface IUseExternalMap {
  map: L.Map | null
  setMap: (map: L.Map) => void
}

const useExternalMap = create<IUseExternalMap>()(
  devtools((set) => ({
    map: null,
    setMap: (map) => set(() => ({ map })),
  })),
)

export default useExternalMap
