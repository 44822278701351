import { devtools } from 'zustand/middleware'
import create from 'zustand'

interface IUseFullScreen {
  isFull: boolean
  setIsFullToggle: () => void
}

const useFullScreen = create<IUseFullScreen>()(
  devtools((set) => ({
    isFull: false,
    setIsFullToggle: () => set((prev) => ({ ...prev, isFull: !prev.isFull })),
  })),
)

export default useFullScreen
