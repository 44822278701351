import React, { useRef } from 'react'
import { Marker, Polyline as P } from 'react-leaflet'
import L, { LatLngExpression, Polyline } from 'leaflet'
import useVideoTime from '../states/useVideoTime'
import drone from '../assets/image/drone.svg'
import start from '../assets/image/startMarker.svg'
import end from '../assets/image/finishMarker.svg'

import 'leaflet.motion/dist/leaflet.motion'
import useSelectedImage from '../states/useSelectedImage'
import useVideoPosition from '../hooks/useVideoPosition'

function VideoPolyLine() {
  const selectedSrcUUID = useSelectedImage((state) => state.selectedSrcUUID)
  const { data } = useVideoPosition(selectedSrcUUID)
  const polyLineRef = useRef<Polyline>(null)
  const { currentTime, videoTime, videoRef, setCurrentTime } = useVideoTime()

  const iconPerson = new L.Icon({
    iconUrl: drone,
    iconRetinaUrl: drone,
    iconSize: L.point(50, 50),
    className: 'drone-icon',
  })

  if (data === undefined) {
    return <div>Loading</div>
  }

  return (
    <>
      <Marker
        position={data[0] as unknown as LatLngExpression}
        icon={
          new L.Icon({
            iconUrl: start,
            iconRetinaUrl: start,
            iconSize: L.point(27.5, 24),
            className: 'drone-icon',
          })
        }
      />
      <Marker
        position={data[data.length - 1] as unknown as LatLngExpression}
        icon={
          new L.Icon({
            iconUrl: end,
            iconRetinaUrl: end,
            iconSize: L.point(27.5, 24),
            className: 'drone-icon',
          })
        }
      />
      <P
        ref={polyLineRef}
        color='#3300FF'
        positions={data as unknown as LatLngExpression[]}
        weight={10}
      />
      <Marker
        position={
          Math.floor(currentTime) >= data.length
            ? (data[data.length - 1] as unknown as LatLngExpression)
            : (data[Math.floor(currentTime)] as unknown as LatLngExpression)
        }
        icon={iconPerson}
      />
      {videoTime > 0 &&
        [20, 40, 60, 80].map((time) => {
          const position = Math.round(videoTime * (time / 100))
          return (
            <P
              key={time}
              color='red'
              positions={[data[position], data[position + 1]]}
              weight={10}
              eventHandlers={{
                click: () => {
                  if (videoRef === null) return
                  if (videoRef.current === null) return

                  videoRef.current.currentTime = position
                  setCurrentTime(position)
                },
              }}
            />
          )
        })}
    </>
  )
}

export default VideoPolyLine
