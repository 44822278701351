import create from 'zustand'
import { devtools } from 'zustand/middleware'

export type TSelectedSrc = string | undefined
export type TSelectedSrcType = 'video' | 'image' | undefined
export type TSelectedSrcUUID = string | undefined
export type TSelectedGeom = { type: string; coordinates: [number, number] } | undefined

export type IToggleSelectedSrc = (
  src: TSelectedSrc,
  thumb: TSelectedSrc,
  type: TSelectedSrcType,
  geom: TSelectedGeom,
  mediaId?: TSelectedSrcUUID,
) => void

interface IUseSelectedImage {
  selectedSrc: TSelectedSrc
  selectedThumb: TSelectedSrc
  selectedSrcType: TSelectedSrcType
  selectedSrcUUID?: TSelectedSrcUUID
  selectedGeom: TSelectedGeom
  toggleSelectedSrc: IToggleSelectedSrc
}

const useSelectedImage = create<IUseSelectedImage>()(
  devtools((set) => ({
    selectedSrc: undefined,
    selectedThumb: undefined,
    selectedSrcType: undefined,
    selectedSrcUUID: undefined,
    selectedGeom: undefined,
    toggleSelectedSrc: (
      src: TSelectedSrc,
      thumb: TSelectedSrc,
      type: TSelectedSrcType,
      geom: TSelectedGeom,
      mediaId: TSelectedSrcUUID = undefined,
    ) =>
      set((state) => {
        if (src === state.selectedSrc || src === undefined) {
          return {
            selectedSrc: undefined,
            selectedThumb: undefined,
            selectedSrcType: undefined,
            selectedSrcUUID: undefined,
            selectedGeom: undefined,
          }
        }
        return {
          selectedSrc: src,
          selectedThumb: thumb,
          selectedSrcType: type,
          selectedSrcUUID: mediaId,
          selectedGeom: geom,
        }
      }),
  })),
)

export default useSelectedImage
