import { createTheme } from '@mui/material'
import breakpoints from './breakpoints'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false // removes the `xs` breakpoint
    sm: false
    md: false
    lg: false
    xl: false
    mobile: true // adds the `mobile` breakpoint
    tablet: true
    laptop: true
    desktop: true
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#256197',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Poppins', 'sans-serif', 'Days One', 'Noto Sans'].join(','),
  },
  breakpoints: {
    values: breakpoints,
  },
})

export default theme
// 'Roboto', sans-serif;
// font-family: 'Poppins', sans-serif;
// font-family: 'Roboto', sans-serif;
