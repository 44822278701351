import * as React from 'react'
import { styled } from '@mui/material'
import LoadingBar from './LoadingBar'
import useUploadProcessTime from '../states/useUploadProcessTime'
import useDialogState from '../states/useDialogState'

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 10px 10px;
  background: #fff;

  font-family: 'Noto Sans NKo', sans-serif;
  font-style: normal;
  line-height: 100%;

  .contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    color: #111111;
  }

  .description {
    margin-top: 8px;

    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #7d7d7d;
  }

  .loading__wrapper {
    width: 100%;
    margin-top: 32px;
    display: grid;
    gap: 20px;
  }

  .text {
    text-align: center;
    line-height: 20px;
  }
`

export default function LoadingPopover() {
  const { uploadItems } = useUploadProcessTime()

  return (
    <Wrapper onMouseLeave={() => useDialogState.setState({ isLoadingOpen: false })}>
      <span data-testid='title' className='title'>
        File Upload
      </span>
      <span data-testid='subTitle' className='description'>
        {uploadItems.length} of {uploadItems.filter((item) => item.time === 100).length} are done
      </span>
      <div className='loading__wrapper'>
        {uploadItems.length !== 0 ? (
          uploadItems.map((item, index) => (
            <LoadingBar time={item.time} index={index + 1} key={item.uuid} />
          ))
        ) : (
          <span className='text'>
            드론 촬영 사진 및 동영상을 <br /> 업로드 해주세요.
          </span>
        )}
      </div>
    </Wrapper>
  )
}
