import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import apis from '../service/apis'
import { IDto } from '../types/Dto'

interface IQuery {
  mediaId: string
}

interface IMetaData {
  make: string
  model: string
  imageCreateDate: string
  focalLength: number
  exposureTime: number
  aperture: number
  iso: number
  longitude: number
  latitude: number
  altitude: number
  softwareModel: string
}

interface IMeta {
  metaData: IMetaData
}

const useMeta = (mediaId: string, mediaType: string) =>
  useQuery<IDto<IMeta>, AxiosError, IMetaData>(
    ['getImageMeta', mediaId, mediaType],
    async () => apis.get<IQuery, IDto<IMeta>>(`/api/v1/media/${mediaType}`, { mediaId }),
    {
      select: (data) => data.data.metaData,
      enabled: mediaId !== undefined || mediaType !== undefined,
    },
  )

export default useMeta
