import { styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import * as React from 'react'
import useUserData from '../states/useUserData'
import CustomPopover from './common/CustomPopover'

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 3.75px;
  background: #fff;

  .menu {
    width: 100%;
    height: 45px;
    font-family: 'Days One';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 131%;
    color: #256197;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3px 0;
    cursor: pointer;
    background: #fff;
    border: none;

    :not(:last-child) {
      border-bottom: 1px dashed rgba(37, 97, 151, 0.8);
    }
  }

  .krFont {
    font-family: 'Gmarket Sans';
  }

  .red__text {
    color: #d64d00;
  }
`

export default function ProfilePopover({ isVisible }: { isVisible: boolean }) {
  const { userData } = useUserData()
  const navigate = useNavigate()
  const [, , removeCookie] = useCookies<string>()
  const checkKr = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/

  const handleLogoutLogin = () => {
    if (userData.userName !== '') {
      if (window.confirm('Are you sure you want to sign out?')) {
        removeCookie('Authorization', { path: '/' })
        useUserData.setState({
          userData: {
            userId: '',
            userName: '',
            userEmail: '',
            userProfilePicture: '',
            accessToken: '',
            refreshToken: '',
            message: '',
          },
        })
        navigate('/')
        window.location.reload()
      }
    } else {
      navigate('/login')
    }
  }

  const handleUpgrade = () => {
    alert("We're preparing.")
  }

  return (
    <CustomPopover visible={isVisible} width='130px' height='fit-content' right='20.14px' isShadow>
      <Wrapper>
        <span
          className={`menu ${
            checkKr.test(userData.userName !== '' ? userData.userName : '') ? 'krFont' : ''
          }`}
        >
          {userData.userName !== '' ? userData.userName : 'user name'}
        </span>
        <span className='menu'>
          <button type='button' className='menu' onClick={handleUpgrade}>
            storage size
            <span>
              10GB <span className='red__text'> upgrade</span>
            </span>
          </button>
        </span>
        <button className='menu' type='button' onClick={handleLogoutLogin}>
          {userData.userName !== '' ? 'logout' : 'login'}
        </button>
      </Wrapper>
    </CustomPopover>
  )
}
