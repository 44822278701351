import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import apis from '../service/apis'
import { IUserData } from '../types/user'

const useGoogleLoginCallback = (url: string) =>
  useQuery<{ data: IUserData; message: string }, AxiosError>(['userData'], async () => {
    const { data }: AxiosResponse<{ data: IUserData; message: string }> = await apis.post(url, {})
    return data
  })

export default useGoogleLoginCallback
