import create from 'zustand'
import { persist } from 'zustand/middleware'
import { IUserData } from '../types/user'

interface UserDataState {
  userData: IUserData
  setUserData: (select: IUserData) => void
}

const useUserData = create<UserDataState>()(
  persist(
    (set) => ({
      userData: {
        userId: '',
        userName: '',
        userEmail: '',
        userProfilePicture: '',
        accessToken: '',
        refreshToken: '',
        message: '',
      },
      setUserData: (data) => set((state) => ({ ...state, userData: data })),
    }),
    { name: 'user-StoreName' },
  ),
)

export default useUserData
