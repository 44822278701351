import * as React from 'react'
import { styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useMutation } from 'react-query'
import MyComputerUploadButton from './MyComputerUploadButton'
import GoogleDriveUploadButton from './GoogleDriveUploadButton'
import useUpload from '../hooks/useUpload'
import useUserData from '../states/useUserData'

const Wrapper = styled('div')`
  font-family: 'Noto Sans NKo', sans-serif;
  font-style: normal;
  line-height: 100%;
  background: #fff;

  .header {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .icon {
    cursor: pointer;
    float: right;
  }

  .contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 45px 40px 40px 40px;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    color: #111111;
  }

  .description {
    margin-top: 8px;

    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #7d7d7d;
  }

  .upload__wrapper {
    margin-top: 38px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;
  }
`

export default function UploadPopover({ handleClose }: { handleClose: () => void }) {
  const [files, setFiles] = React.useState<FileList | null>(null)
  const { userData } = useUserData()
  const { mutate } = useMutation(useUpload(userData.userId, files))

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    if (target.files !== null) {
      setFiles(target.files)
    }
  }

  React.useEffect(() => {
    if (files !== null) {
      mutate()
    }
  }, [files, mutate])

  return (
    <Wrapper>
      <div data-testid='closeButton' className='header'>
        <CloseIcon className='icon' onClick={() => handleClose()} />
      </div>
      <div className='contents'>
        <span data-testid='title' className='title'>
          Files Upload
        </span>
        <span data-testid='subTitle' className='description'>
          Upload saved photos to
        </span>
        <div className='upload__wrapper'>
          <MyComputerUploadButton handleChange={handleImageUpload} />
          <GoogleDriveUploadButton />
        </div>
      </div>
    </Wrapper>
  )
}
